// VAR'S
// ============================\\
$primaryColor: #003C72;
$secondaryColor: #e5f5fc;

/************* LOGO *************/
$logoWidth: 296px;
$logoHeight: 94px;
$logoImage: image-url('whitelabels/logo-assen.png'); 

/************* header *************/
$showHeaderLinks: false;
$headerHeight: 7.187rem;
$headerNavBg: $primaryColor;
$headerNavColor: #FFF;
$headerBorderBottom: none;
$headerMarginBottom: 0;

/************* AUTH *************/
$authBgColor: $secondaryColor;
$authTextColor: #000;
$authRequestColor: #000;

/************* CONTENT *************/
// $contentPadding: 0;
$contentBg: #FFF;
$contentBgGenerator: #FFF;
// $contentBoxShadow: 0 1px 1px 1px #ccc;
$contentH1Padding: 10px;

/************* FOOTER *************/
$footerShowHouses: false;
$footerAllBg: $secondaryColor;
$footerColorBg: transparent;

/************* INFO BLOCK *************/
$infoBlockBg: #FFF;
$infoBlockBoxShadow: none;

/************* OTHER *************/
$mainBg: $secondaryColor;
$containerBg: $secondaryColor;
$containerInnerSmallBg: none;
$containerInnerLargeBg: none;
$schetsBottomBg: none;
$schetsBottomBgGenerator: none;
// $color-border2: #ededee;
$adminMenuTop: auto;
$adminSubMenuBg: #00adef;

@import '../application'
